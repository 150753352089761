import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Spin, Select } from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import TextArea from "antd/es/input/TextArea";

export default function SupplierModal({
  data,
  handleModalClose,
  fetchSupplierList,
  visible,
  isUpdate,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [brandList, setBrandList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    form.setFieldsValue({
      supplier_name: data ? data?.supplier_name : null,
      supplier_phone: data ? "0" + data?.supplier_phone : null,
      brand_id: data ? data?.brand?.brand_id : null,
      supplier_type: data ? data?.supplier_type : null,
      address: data ? data?.address : null,
    });
  });
  useEffect(() => {
    axios
      .get(`${baseUrl}/inventory/brand_list`, {
        headers: authHeader(),
      })
      .then((response) => {
        setBrandList(response?.data || []);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);
  const handleSupplierModalClose = () => {
    handleModalClose();
    form.setFieldsValue({
      supplier_name: "",
      supplier_phone: null,
      brand_id: null,
      supplier_type: null,
      address: "",
    });
  };
  const handleSubmit = (value) => {
    isUpdate
      ? axios
          .patch(
            `${baseUrl}/inventory/update_supplier/${data?.supplier_id}/`,
            value,
            {
              headers: authHeader(),
            }
          )
          .then((response) => {
            console.log(response);
            if (response?.status === 200) {
              Swal.fire({
                title: "Success",
                text: "Supplier Updated Successful",
                confirmButtonText: "Ok",
                icon: "success",
              }).then(() => {
                fetchSupplierList();
                handleSupplierModalClose();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error,
              confirmButtonText: "Ok",
              icon: "error",
            });
          })
      : axios
          .post(`${baseUrl}/inventory/create_supplier/`, value, {
            headers: authHeader(),
          })
          .then((response) => {
            console.log(response);
            if (response?.status === 201) {
              Swal.fire({
                title: "Success",
                text: "Supplier Created Successful",
                confirmButtonText: "Ok",
                icon: "success",
              }).then(() => {
                fetchSupplierList();
                handleSupplierModalClose();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error,
              confirmButtonText: "Ok",
              icon: "error",
            });
          });
  };
  return (
    <Modal
      title={(isUpdate ? "Update" : "Add") + " Supplier"}
      open={visible}
      onCancel={handleSupplierModalClose}
      footer={false}
    >
      <Form form={form} onFinish={handleSubmit} layout="horizontal">
        <Form.Item
          label="Supplier Name"
          name="supplier_name"
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: "Supplier name required" }]}
        >
          <Input placeholder="Supplier Name" />
        </Form.Item>
        <Form.Item
          label="Supplier Phone	"
          name="supplier_phone"
          labelCol={{ span: 6 }}
          rules={[
            { required: true, message: "Supplier phone required" },
            {
              pattern: /^(?:\+?88)?01[3-9]\d{8}$/,
              message: "Please enter a valid phone number",
            },
          ]}
        >
          <Input placeholder="Supplier Phone" />
        </Form.Item>
        <Form.Item
          label="Brand"
          name="brand_id"
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: "Select Brand" }]}
        >
          <Select placeholder="Select Brand">
            {brandList?.map((brand) => (
              <Option key={brand?.brand_id} value={brand?.brand_id}>
                {brand?.brand_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Supplier Type"
          name="supplier_type"
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: "Select Brand" }]}
        >
          <Select placeholder="Select Supplier Type">
            <Option value="Company">Company</Option>
            <Option value="Wholesaler">Wholesaler</Option>
            <Option value="Farmer">Farmer</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: "Address Required" }]}
        >
          <TextArea placeholder="Address" />
        </Form.Item>

        <Form.Item>
          <Button
            className="ms-3"
            style={{
              backgroundColor: "#46604A",
              borderColor: "#46604A",
              color: "white",
            }}
            htmlType="submit"
            disabled={loading}
          >
            {isUpdate ? "Update" : "Submit"}
            {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
