import React, { useEffect, useState } from "react";
import { Button, Input, Table, Tooltip } from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import SupplierModal from "./SupplierModal";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

export default function SupplierList() {
  const { Search } = Input;
  const [supplierList, setSupplierList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [supplierModalVisible, setSupplierModalVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const handleSearch = (value) => {
    setCurrentPage(1);
    setSearch(value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSupplierModal = () => {
    setSupplierModalVisible(true);
  };
  const closeSupplierModal = () => {
    setSupplierModalVisible(false);
    setIsUpdate(false);
    setSelectedData();
  };
  const handleClickUpdate = (record) => {
    setSupplierModalVisible(true);
    setIsUpdate(true);
    setSelectedData(record);
  };

  const fetchSupplierList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}/inventory/paginated_supplier_list/`, {
        headers: authHeader(),
        params: {
          filter_text: search,
          page: currentPage,
        },
      })
      .then((response) => {
        setLoading(false);
        setSupplierList(response?.data?.results);
        setTotalItems(response?.data?.count);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };
  useEffect(() => {
    fetchSupplierList();
  }, [currentPage, search]);

  const handleDelete = (record) => {
    Swal.fire({
      title: "Warning",
      text: `Are you sure you want to delete ${record?.supplier_name} supplier?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${baseUrl}/inventory/delete_supplier/${record?.supplier_id}`,
            {
              headers: authHeader(),
            }
          )
          .then((response) => {
            console.log(response);
            if (response?.status === 204) {
              Swal.fire({
                title: "Success",
                text: "Supplier Deleted Successfully",
                confirmButtonText: "Ok",
                icon: "success",
              }).then(() => {
                fetchSupplierList();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error,
              confirmButtonText: "Ok",
              icon: "error",
            });
          });
      }
    });
  };

  const supplierColumn = [
    {
      title: "Supplier ID",
      render: (record) => record?.supplier_id,
      width: "10%",
    },
    {
      title: "Supplier Name",
      render: (record) => record?.supplier_name,
      width: "8%",
    },
    {
      title: "Supplier Type",
      render: (record) => record?.supplier_type,
      width: "8%",
    },
    {
      title: "Supplier Phone",
      render: (record) => "0" + record?.supplier_phone,
      width: "10%",
    },
    {
      title: "Brand",
      render: (record) => record?.brand?.brand_name,
      width: "10%",
    },
    {
      title: "Address",
      render: (record) => record?.address,
      width: "10%",
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Tooltip title="Update">
              <Button
                className="d-flex align-items-center me-1"
                onClick={() => handleClickUpdate(record)}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                className="d-flex align-items-center me-1"
                onClick={() => handleDelete(record)}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
      width: "5%",
    },
  ];
  return (
    <>
      {supplierModalVisible && (
        <SupplierModal
          data={selectedData}
          handleModalClose={closeSupplierModal}
          fetchSupplierList={fetchSupplierList}
          visible={supplierModalVisible}
          isUpdate={isUpdate}
        />
      )}
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>SUPPLIER DETAILS</h5>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div className="shadow-sm p-3">
              <div className="m-2 mt-4 d-flex justify-content-between align-items-center">
                <Search
                  className="w-25"
                  placeholder="search here..."
                  allowClear
                  onSearch={handleSearch}
                  enterButton={
                    <Button
                      style={{
                        backgroundColor: "#46604A",
                        borderColor: "#46604A",
                        color: "white",
                      }}
                    >
                      Search
                    </Button>
                  }
                />
                <Button
                  style={{
                    backgroundColor: "#46604A",
                    borderColor: "#46604A",
                    color: "white",
                  }}
                  onClick={handleSupplierModal}
                >
                  Add Supplier
                </Button>
              </div>
              <Table
                className="border"
                columns={supplierColumn}
                dataSource={supplierList}
                loading={loading}
                rowKey={(record) => record?.supplier_id}
                pagination={{
                  current: currentPage,
                  pageSize: 10,
                  total: totalItems,
                  onChange: handlePageChange,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
