import React, { useEffect, useState } from "react";
import { Button, Input, Select, Table} from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import { DatePicker } from "antd";
import dayjs from "dayjs";

export default function DamageStock() {
  const { Search } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [damageStockList, setDamageStockList] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [centerIds, setCenterIds] = useState([]);
  const [damageStockLoading, setDamageStockLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedCenter, setSelectedCenter] = useState("");
  const [userProfile, setUserProfile] = useState(
    JSON.parse(localStorage.getItem("userProfile"))
  );
  const [selectedDateRange, setSelectedDateRange] = useState([]);

  const handleSalesDateRage = (value) => {
    if (value?.length === 2) {
      const startDate = dayjs(value[0]).startOf("day");
      const endDate = dayjs(value[1]).endOf("day");
      const updatedDateRange = [startDate, endDate];
      setSelectedDateRange(updatedDateRange);
    } else {
      setSelectedDateRange(null);
    }
  };

  const handleSearch = (value) => {
    setCurrentPage(1);
    setSearch(value);
  };
  const handleCenter = (value) => {
    setCurrentPage(1);
    setSelectedCenter(value);
  };
  const handlePageChange = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  const fetchStockList = () => {
    setDamageStockLoading(true);
    axios
      .get(`${baseUrl}/inventory/damage_stock_list/${userProfile?.employee_id}/`, {
        headers: authHeader(),
        params: {
          product_name: search,
          searched_center_id: selectedCenter,
          dateRange: selectedDateRange,
          page: currentPage,
        },
      })
      .then((response) => {
        setDamageStockLoading(false);
        setDamageStockList(response?.data?.results);
        setTotalItems(response?.data?.count);
      })
      .catch((error) => {
        setDamageStockLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };
  useEffect(() => {
    fetchStockList();
  }, [currentPage, search, selectedCenter, selectedDateRange]);

  useEffect(() => {
    setDamageStockLoading(true);
    axios
      .get(`${baseUrl}/inventory/center_list/`, {
        headers: authHeader(),
      })
      .then((response) => {
        setDamageStockLoading(false);
        setCenterList(response?.data);
        const employeeIds = response?.data?.map(
          (center) => center?.center?.employee_id
        );
        setCenterIds(employeeIds);
      })
      .catch((error) => {
        setDamageStockLoading(true);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  }, []);

  const stockColumn = [
    {
      title: "Product Name",
      render: (record) => record?.product?.product_name,
      width: "10%",
    },
    {
        title: "Center",
        render: (record) => record?.center?.center_name,
        width: "8%",
      },
    {
      title: "Batch",
      dataIndex: "batch_id",
      width: "10%",
    },
    {
      title: "Agri Type",
      render: (record) => record?.product?.agri_type,
      width: "10%",
    },
    {
      title: "Category",
      render: (record) => record?.product?.category?.category_name,
      width: "10%",
    },
    {
      title: "Brand Name",
      render: (record) => record?.product?.brand?.brand_name,
      width: "10%",
    },
    {
      title: "Quantity In",
      dataIndex: "quantity_in",
      width: "10%",
    },
    {
      title: "Quantity Out",
      dataIndex: "quantity_out",
      width: "10%",
    },
    {
      title: "Remaining Stock",
      dataIndex: "remaining_quantity",
      width: "8%",
    },
    {
      title: "Expire",
      render: (record) =>
        record?.expire_date
          ? new Date(record?.expire_date).toISOString().slice(0, 10)
          : "",
      width: "8%",
    },
   
      {
        title: "Damage Stock Value",
        render: (record) =>
          (record?.purchase_price * record?.remaining_quantity).toLocaleString(),
        width: "8%",
      }
   ,
    // {
    //   title: "Action",
    //   width: "5%",
    //   render: (record) => {
    //     return (
    //       <React.Fragment>
    //         {centerIds.includes(userProfile?.employee_id) ? null : (
    //           <div className="d-flex">
    //             <Tooltip title="Update">
    //               <Button
    //                 className="d-flex align-items-center me-1"
    //                 onClick={() => handleUpdateModal(record)}
    //               >
    //                 <EditOutlined />
    //               </Button>
    //             </Tooltip>
    //             <Tooltip title="Damage">
    //               <Button
    //                 className="d-flex align-items-center"
    //                 onClick={() => handleDamageModal(record)}
    //               >
    //                 <RestOutlined />
    //               </Button>
    //             </Tooltip>
    //           </div>
    //         )}
    //       </React.Fragment>
    //     );
    //   },
    // },
  ];
  return (
    <>
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>DAMAGE STOCK DETAILS</h5>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div className="shadow-sm p-3">
              <div className="m-2 mt-4 d-flex justify-content-between align-items-center">
                <div>
                  {centerIds?.includes(userProfile?.employee_id) ? null : (
                    <Select
                      placeholder="Select Center"
                      className="me-3"
                      onChange={handleCenter}
                      style={{ width: "200px" }}
                    >
                      <Option value="">All Center</Option>
                      {centerList?.map((center) => (
                        <Option
                          value={center?.center?.employee_id}
                          key={center?.center?.employee_id}
                        >
                          {center?.center_name}
                        </Option>
                      ))}
                    </Select>
                  )}
                  <RangePicker
                    onChange={handleSalesDateRage}
                    value={selectedDateRange}
                    style={{ width: "250px" }}
                  />
                </div>
                <Search
                  className="w-25"
                  placeholder="search here..."
                  allowClear
                  onSearch={handleSearch}
                  enterButton={
                    <Button
                      style={{
                        backgroundColor: "#46604A",
                        borderColor: "#46604A",
                        color: "white",
                      }}
                    >
                      Search
                    </Button>
                  }
                />
              </div>
              <Table
                className="border"
                columns={stockColumn}
                dataSource={damageStockList}
                loading={damageStockLoading}
                rowKey={(record, index) => index}
                pagination={{
                  current: currentPage,
                  pageSize: 10,
                  total: totalItems,
                  onChange: handlePageChange,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

