import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Spin, Select } from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import TextArea from "antd/es/input/TextArea";

export default function BrandModal({
  data,
  handleModalClose,
  fetchBrandList,
  visible,
  isUpdate,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    form.setFieldsValue({
      brand_name: data ? data?.brand_name : null,
    });
  });

  const handleBrandModalClose = () => {
    handleModalClose();
    form.setFieldsValue({
      brand_name: "",
    });
  };
  const handleSubmit = (value) => {
    isUpdate
      ? axios
          .patch(
            `${baseUrl}/inventory/update_brand/${data?.brand_id}/`,
            value,
            {
              headers: authHeader(),
            }
          )
          .then((response) => {
            console.log(response);
            if (response?.status === 200) {
              Swal.fire({
                title: "Success",
                text: "Brand Updated Successful",
                confirmButtonText: "Ok",
                icon: "success",
              }).then(() => {
                fetchBrandList();
                handleBrandModalClose();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error,
              confirmButtonText: "Ok",
              icon: "error",
            });
          })
      : axios
          .post(`${baseUrl}/inventory/create_brand/`, value, {
            headers: authHeader(),
          })
          .then((response) => {
            console.log(response);
            if (response?.status === 201) {
              Swal.fire({
                title: "Success",
                text: "Brand Created Successful",
                confirmButtonText: "Ok",
                icon: "success",
              }).then(() => {
                fetchBrandList();
                handleBrandModalClose();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error,
              confirmButtonText: "Ok",
              icon: "error",
            });
          });
  };
  return (
    <Modal
      title={(isUpdate ? "Update" : "Add") + " Brand"}
      open={visible}
      onCancel={handleBrandModalClose}
      footer={false}
    >
      <Form form={form} onFinish={handleSubmit} layout="horizontal">
        <Form.Item
          label="Brand Name"
          name="brand_name"
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: "Brand name required" }]}
        >
          <Input placeholder="Brand Name" />
        </Form.Item>

        <Form.Item>
          <Button
            className="ms-3"
            style={{
              backgroundColor: "#46604A",
              borderColor: "#46604A",
              color: "white",
            }}
            htmlType="submit"
            disabled={loading}
          >
            {isUpdate ? "Update" : "Submit"}
            {loading && <Spin size="small" style={{ marginLeft: 8 }} />}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
