import React, { useEffect, useState } from "react";
import { Button, Input, Table, Tooltip } from "antd";
import axios from "axios";
import baseUrl from "../../config";
import authHeader from "../Authentication/authHeader";
import Swal from "sweetalert2";
import SupplierModal from "./SupplierModal";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import BrandModal from "./BrandModal";

export default function BrandList() {
  const { Search } = Input;
  const [brandList, setBrandList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [brandModalVisible, setBrandModalVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const handleSearch = (value) => {
    setCurrentPage(1);
    setSearch(value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleBrandModal = () => {
    setBrandModalVisible(true);
  };
  const closeBrandModal = () => {
    setBrandModalVisible(false);
    setIsUpdate(false);
    setSelectedData();
  };
  const handleClickUpdate = (record) => {
    setBrandModalVisible(true);
    setIsUpdate(true);
    setSelectedData(record);
  };

  const fetchBrandList = () => {
    setLoading(true);
    axios
      .get(`${baseUrl}/inventory/paginated_brand_list/`, {
        headers: authHeader(),
        params: {
          filter_text: search,
          page: currentPage,
        },
      })
      .then((response) => {
        setLoading(false);
        setBrandList(response?.data?.results);
        setTotalItems(response?.data?.count);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: error,
          confirmButtonText: "Ok",
          icon: "error",
        });
      });
  };
  useEffect(() => {
    fetchBrandList();
  }, [currentPage, search]);

  const handleDelete = (record) => {
    Swal.fire({
      title: "Warning",
      text: `Are you sure you want to delete ${record?.brand_name} brand?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/inventory/delete_brand/${record?.brand_id}`, {
            headers: authHeader(),
          })
          .then((response) => {
            console.log(response);
            if (response?.status === 204) {
              Swal.fire({
                title: "Success",
                text: "Brand Deleted Successfully",
                confirmButtonText: "Ok",
                icon: "success",
              }).then(() => {
                fetchBrandList();
              });
            }
          })
          .catch((error) => {
            console.log(error)
            Swal.fire({
              title: "Error",
              text: error?.response?.data?.error,
              confirmButtonText: "Ok",
              icon: "error",
            });
          });
      }
    });
  };

  const supplierColumn = [
    {
      title: "Brand ID",
      render: (record) => record?.brand_id,
      width: "10%",
    },
    {
      title: "Brand",
      render: (record) => record?.brand_name,
      width: "10%",
    },

    {
      title: "Action",
      render: (record) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Tooltip title="Update">
              <Button
                className="d-flex align-items-center me-1"
                onClick={() => handleClickUpdate(record)}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                className="d-flex align-items-center me-1"
                onClick={() => handleDelete(record)}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        );
      },
      width: "5%",
    },
  ];
  return (
    <>
      {brandModalVisible && (
        <BrandModal
          data={selectedData}
          handleModalClose={closeBrandModal}
          fetchBrandList={fetchBrandList}
          visible={brandModalVisible}
          isUpdate={isUpdate}
        />
      )}
      <div className="farmerDashboard">
        <div className="dashboardNameSection">
          <h5 style={{ color: "#46604A" }}>BRAND DETAILS</h5>
        </div>
        <div className="farmerDashboardContainer shadow-sm rounded">
          <div className="pt-5" style={{ width: "95%", margin: "auto" }}>
            <div className="shadow-sm p-3">
              <div className="m-2 mt-4 d-flex justify-content-between align-items-center">
                <Search
                  className="w-25"
                  placeholder="search here..."
                  allowClear
                  onSearch={handleSearch}
                  enterButton={
                    <Button
                      style={{
                        backgroundColor: "#46604A",
                        borderColor: "#46604A",
                        color: "white",
                      }}
                    >
                      Search
                    </Button>
                  }
                />
                <Button
                  style={{
                    backgroundColor: "#46604A",
                    borderColor: "#46604A",
                    color: "white",
                  }}
                  onClick={handleBrandModal}
                >
                  Add Brand
                </Button>
              </div>
              <Table
                className="border"
                columns={supplierColumn}
                dataSource={brandList}
                loading={loading}
                rowKey={(record) => record?.brand_id}
                pagination={{
                  current: currentPage,
                  pageSize: 10,
                  total: totalItems,
                  onChange: handlePageChange,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
